/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import { Button, Skeleton, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import dynamic from "next/dynamic";
import React from "react";

/*

* Next, let's dynamically import all the UI sub-components we intend to use on this page. we dont need it on inital load. Api needs to trigger till then we show loading component which we will import it normally.

*/
const BusinessProfileCard = dynamic(() => import("../../cards/BusinessProfileCard").then((mod) => mod.default));
const PropertyCardThree = dynamic(() =>
	import("../../cards/PropertyCardGridMobileThree/PropertyCardFetcher").then((mod) => mod.default),
);
const PropertyCardTwo = dynamic(() =>
	import("../../cards/PropertyCardGridMobileTwo/PropertyCardFetcher").then((mod) => mod.default),
);
const PropertyCard = dynamic(() =>
	import("../../cards/PropertyCardGridView/PropertyCardFetcher").then((mod) => mod.default),
);
const ServiceCard = dynamic(() =>
	import("../../cards/ServiceCardGridView/ServiceCardFetcher").then((mod) => mod.default),
);

/*

* let's check if mobile or desktop to import the correct card component. this is the function

*/
// const isMobile = () => {
// 	if (typeof window !== "undefined") {
// 		return window.innerWidth <= 768;
// 	}
// 	return false;
// };

/*

* lets call the function to check if mobile or desktop and import the correct card component

*/
// const FeaturedPropertiesCard = dynamic(
// 	() => {
// 		/*

// 		* mobile calls the isobile function

// 		*/
// 		const mobile = isMobile();
// 		if (mobile) {
// 			return import("../../cards/FeaturedCard/FeaturedMobileListCard").then((mod) => mod.default);
// 		} else {
// 			return import("../../cards/FeaturedCard/FeaturedListCard").then((mod) => mod.default);
// 		}
// 	},
// 	{ ssr: false },
// );

import BusinessProfileLoadingCard from "../../cards/skeletonLoadingCard/BusinessProfileLoadingCard";
import PropertyLoadingCard from "../../cards/skeletonLoadingCard/PropertyLoadingCard";
/*

? Next, let's import all the icons we intend to use on this page.

*/

import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/*

? Next, let's import all redux related components.

*/

import { useRouter } from "next/router";
import StateInterface from "../../../redux-magic/state-interface";
import { setSearchAnimationDetailsThunk, updateSearchFilterSelectedOptionsThunk } from "../../../redux-magic/store";
import { updateSearchFilterAppliedThunk, updateSearchFilterVisibilityThunk } from "../../../redux-magic/thunks";
import BusinessProfileCardGridView from "../../cards/BusinessProfileCards/BusinessProfileCardTest";
import ServiceLoadingCard from "../../cards/skeletonLoadingCard/ServiceLoadingCard";
import BeegruButton from "../../common-components/buttons/BeegruButton";

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	index?: number;
};

const SectionContainer = styled("div")<CustomAttributesForDivElement>(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	height: "100%",
	margin: "0rem 0rem 0rem 0rem",
}));

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "1rem",
	flexWrap: "nowrap",
	scrollBehavior: "smooth",
	overflowX: "scroll",
	scrollSnapType: "x mandatory",
	"&::-webkit-scrollbar": {
		display: "none",
	},
}));

const InnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	// gap: "1rem",
	// [theme.breakpoints.down("sm")]: {
	// 	gap: "0.5rem",
	// },
}));

const GradientHeading = styled("span")(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 500,
	color: theme.palette.text.primary,
	padding: "0rem 0rem 0rem 0.25rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "1.25rem",
		lineHeight: "1.5rem",
		padding: "0rem 0rem 0.5rem 0.25rem",
	},
}));

const Columncontain = styled("div")(({ theme }) => ({
	display: "flex",
	position: "relative",
	// minHeight: "13.5rem",
	"&:hover": {
		"${LeftArrowContainer}": {
			display: "flex",
		},
	},
}));

const LeftArrowContainer = styled(Button)(({ theme }) => ({
	display: "flex",
	position: "absolute",
	justifyContent: "center",
	alignItems: "center",
	minWidth: "0px",
	background: theme.palette.mode === "dark" ? "black" : "white",
	padding: "1.5rem 1.5rem 1.5rem 1.5rem",
	left: -25,
	zIndex: 5,
	"&:hover": {
		background: theme.palette.mode === "dark" ? "black" : "white",
	},
	scrollBehavior: "smooth",
	borderRadius: "50%",
}));

const ArrowLeft = styled(ArrowBackOutlinedIcon)(({ theme }) => ({
	display: "flex",
	position: "absolute",
	cursor: "pointer",
	justifyContent: "center",
	alignItems: "center",
	// stroke: "black",
	fontSize: "2.5rem",
	fill: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.56)" : "rgba(0, 0, 0, 0.54)",
	width: "1.5rem",
}));

const ArrowRight = styled(ArrowForwardIcon)(({ theme }) => ({
	display: "flex",
	cursor: "pointer",
	position: "absolute",
	// right: 12,
	flexDirection: "column",
	// stroke: "white",
	fill: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.56)" : "rgba(0, 0, 0, 0.54)",
	width: "1.5rem",
}));

const RighArrowContainer = styled(Button)(({ theme }) => ({
	display: "flex",
	position: "absolute",
	justifyContent: "center",
	alignItems: "center",
	zIndex: 5,
	background: theme.palette.mode === "dark" ? "black" : "white",
	padding: "1.5rem 1.5rem 1.5rem 1.5rem",
	minWidth: "0px",
	right: -23,
	"&:hover": {
		background: theme.palette.mode === "dark" ? "black" : "white",
	},
	scrollBehavior: "smooth",
	borderRadius: "50%",
}));

const CardCarousel = ({
	index,
	data,
	loading,
	type,
	profile_context,
	dispatch,
	session,
	innerRef,
	searchanimationsettings,
}: {
	index: number;
	data: Array<any>;
	loading: boolean;
	type: string;
	profile_context: StateInterface["profile_context"];
	dispatch: Function;
	session: any;
	innerRef: any;
	searchanimationsettings: StateInterface["search_animation_settings"];
}) => {
	const theme = useTheme();
	/*

	& Next, Let's create a function to scroll the container.

	*/

	const ref = React.useRef<any>();

	const scroll = (scrollOffset: number) => {
		ref.current.scrollLeft += scrollOffset;
	};

	/*

	& This function is used to check if the device is touch enabled or not

	& @returns boolean

	*/

	const isTouchSupported = () => {
		return "ontouchstart" in window || navigator.maxTouchPoints;
	};

	/*

    & Next, let's declare a  local state variable to store the value of the display property of the scroll arrows.

  	*/

	const [display, setDisplay] = React.useState<string>("none");

	/*

    & Next, let's declare local state variables to store whether user can scroll left or right.

  	*/

	// const [canScrollLeft, setCanScrollLeft] = React.useState(false);
	// const [canScrollRight, setCanScrollRight] = React.useState(false);

	/*

    & Next, let's define a function to show the hover effect.

  */

	const ShowHover = () => {
		if (!isTouchSupported()) {
			setDisplay(display === "none" ? "flex" : "none");
		} else {
			return false;
		}
	};

	/*

    & Next, let's define a function to hide the hover effect.

  	*/

	const HideHover = () => {
		if (!isTouchSupported()) {
			setDisplay(display === "flex" ? "none" : "none");
		} else {
			return false;
		}
	};

	/*

    & Next, let's define a function to hide arrow id there is nothing to scroll on that side.

  	*/

	// React.useEffect(() => {
	// 	const handleScroll = () => {
	// 		if (ref.current) {
	// 			const { scrollLeft, scrollWidth, clientWidth } = ref.current;
	// 			setCanScrollLeft(scrollLeft > 3);
	// 			setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
	// 		}
	// 	};

	// 	const carousel = ref.current;
	// 	if (carousel) {
	// 		carousel.addEventListener("scroll", handleScroll);
	// 		handleScroll(); // Initial check
	// 	}

	// 	return () => {
	// 		if (carousel) {
	// 			carousel.removeEventListener("scroll", handleScroll);
	// 		}
	// 	};
	// }, []);

	// React.useEffect(() => {
	// 	// Initial check after the component mounts and renders
	// 	if (ref.current) {
	// 		const { scrollLeft, scrollWidth, clientWidth } = ref.current;
	// 		setCanScrollLeft(scrollLeft > 3);
	// 		setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
	// 	}
	// }, [data]);
	const router = useRouter();

	const viewAll = (type: string) => {
		// return async (dispatch: Function) => {
		switch (type) {
			case "hot_properties":
				// return async (dispatch: Function) => {
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

					* Setting search Type to properties and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "properties",
										title: "Properties",
										value: "properties",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

					* Setting Transaction Type to buy and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "transaction_type",
								newOptions: [
									{
										id: "rent",
										title: "Rent",
										value: "rent",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "transaction_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_category",
								isVisible: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_type",
								isVisible: true,
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "property_category",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "property_type",
								isApplied: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;
			case "top_services":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "property_category",
								newOptions: [],
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "property_type",
								newOptions: [],
							}),
						),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: true })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: true })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: true })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: true })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: true })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false })),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "transaction_type",
								newOptions: [],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "transaction_type",
								isApplied: false,
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "services",
										title: "Services",
										value: "services",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;
			case "trending_services":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "property_category",
								newOptions: [],
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "property_type",
								newOptions: [],
							}),
						),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: true })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: true })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: true })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: true })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: true })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false })),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "transaction_type",
								newOptions: [],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "transaction_type",
								isApplied: false,
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "services",
										title: "Services",
										value: "services",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});

				break;
			case "top_realtors":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "property_category",
								newOptions: [],
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "property_type",
								newOptions: [],
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "business_profiles",
										title: "Business Profiles",
										value: "business_profiles",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "business_profile_type",
								newOptions: [
									{
										id: "agents",
										title: "Agents",
										value: "agents",
									},
								],
							}),
						),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: true })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: true })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_status", isVisible: false })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false })),
						dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false })),
						dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false })),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "transaction_type",
								newOptions: [],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "transaction_type",
								isApplied: false,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;
		}
		// };
	};

	const dataToRender: Function = (type: string, data: any): any => {
		const heading = (type: string) => {
			return (
				<SectionContainer>
					{/* Heading & Subheading */}

					{!loading && data && data[0] !== 0 ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "0.5rem",
								// marginBottom: "0.5rem",
							}}
						>
							<GradientHeading>
								{type === "hot_properties" || type === "trending_properties"
									? "Featured properties"
									: type.charAt(0).toUpperCase() + type.slice(1).split("_").join(" ")}
							</GradientHeading>

							{/* <GradientHeading
								sx={{
									fontSize: "1rem",
									color: theme.palette.text.secondary,
									[theme.breakpoints.down("xsPlus")]: {
										fontSize: "0.875rem",
									},
									[theme.breakpoints.down(theme.breakpoints.values.xs + 100)]: {
										fontSize: "0.75rem",
									},
								}}
							>
								{type === "top_business_profiles"
									? "Most viewed business profiles in your area"
									: type === "hot_properties"
										? "Noteworthy properties in your area"
										: type === "top_services"
											? "Most viewed services in your area"
											: type === "trending_services"
												? "Noteworthy services in your area"
												: type === "trending_properties"
													? "Nearby properties in your area"
													: ""}
							</GradientHeading> */}
						</div>
					) : (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "0.5rem",
								// marginBottom: "0.5rem",
							}}
						>
							<Skeleton
								animation="wave"
								variant="text"
								width="12rem"
								height="2.25rem"
							/>
							{/* <Skeleton
								animation="wave"
								variant="text"
								width="18rem"
								height="1.45rem"
							/> */}
						</div>
					)}

					{/* View All button */}

					{!loading && data && data[0] !== 0 ? (
						<div
							style={{
								display: "flex",
								alignItems: "flex-start",
							}}
						>
							<BeegruButton
								flavour="primary"
								variant="text"
								onClick={() => {
									viewAll(type);
								}}
								sx={{
									fontSize: "0.75rem",
									[theme.breakpoints.up("sm")]: {
										fontSize: "0.825rem",
									},
								}}
							>
								see more
								<ArrowForwardIcon
									fontSize="medium"
									sx={{
										fontSize: "0.875rem",
									}}
								/>
							</BeegruButton>
						</div>
					) : (
						<Skeleton
							animation="wave"
							variant="text"
							width="6rem"
							height="2rem"
						/>
					)}
				</SectionContainer>
			);
		};

		switch (type) {
			case "hot_properties":
				return (
					<React.Fragment>
						{heading(type)}

						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container
								ref={ref}
								sx={{ gap: "0.5rem" }}
							>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{data !== undefined ? (
									<React.Fragment>
										{data?.map((property: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<PropertyCardThree
														PropertyId={property._id}
														dispatch={dispatch}
														session={session}
														profile_context={profile_context}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : (
									<InnerContainer>
										<PropertyLoadingCard />
									</InnerContainer>
								)}
								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			case "trending_properties":
				return (
					<React.Fragment>
						{heading(type)}

						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container ref={ref}>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{data !== undefined ? (
									<React.Fragment>
										{data?.map((property: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<PropertyCardTwo
														PropertyId={property._id}
														dispatch={dispatch}
														session={session}
														profile_context={profile_context}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : (
									<InnerContainer>
										<PropertyLoadingCard />
									</InnerContainer>
								)}

								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			case "top_realtors":
				return (
					<React.Fragment>
						{heading(type)}

						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container ref={ref}>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{!loading && data && data[0] !== 0 ? (
									<React.Fragment>
										{data?.map((business_profile: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<BusinessProfileCardGridView _id={business_profile._id} />
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : (
									<React.Fragment>
										<InnerContainer>
											<BusinessProfileLoadingCard />
										</InnerContainer>
									</React.Fragment>
								)}

								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			case "top_users":
				return (
					<React.Fragment>
						{heading(type)}
						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container ref={ref}>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{!loading ? (
									<React.Fragment>
										{data?.map((user: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<BusinessProfileCard
														loading={loading}
														profile_id={user._id}
														slug={user.slug}
														display_picture={user.profile_picture}
														title={(user.first_name + " " + user.last_name).toString()}
														profile_type={"user"}
														is_subscribed={user?.subscription_information?.active}
														is_kyc_verified={user.verified}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : (
									<React.Fragment>
										<InnerContainer>
											<BusinessProfileLoadingCard />
										</InnerContainer>
									</React.Fragment>
								)}

								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			case "top_services":
				return (
					<React.Fragment>
						{heading(type)}

						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container ref={ref}>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{data !== undefined ? (
									<React.Fragment>
										{data?.map((service: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<ServiceCard
														ServiceId={service._id}
														dispatch={dispatch}
														session={session}
														preview={false}
														profile_context={profile_context}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : (
									<InnerContainer>
										<ServiceLoadingCard />
									</InnerContainer>
								)}

								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			case "trending_services":
				return (
					<React.Fragment>
						{heading(type)}

						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container ref={ref}>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{data !== undefined ? (
									<React.Fragment>
										{data?.map((service: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<ServiceCard
														ServiceId={service._id}
														dispatch={dispatch}
														session={session}
														preview={false}
														profile_context={profile_context}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : (
									<InnerContainer>
										<ServiceLoadingCard />
									</InnerContainer>
								)}

								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			default:
				return <React.Fragment></React.Fragment>;
		}
	};

	return (
		<React.Fragment>
			<div
				ref={innerRef}
				style={{ margin: "0.75rem 0rem 0rem 0rem" }}
			>
				{dataToRender(type, data)}
			</div>
		</React.Fragment>
	);
};

export default CardCarousel;
// case "featured_properties":
// 	return (
// 		<React.Fragment>
//	{heading(type)}
// 			<Columncontain
// 				onMouseEnter={ShowHover}
// 				onMouseLeave={HideHover}
// 			>
// 				<Container ref={ref}>
// 					<LeftArrowContainer
// 						variant="contained"
// 						onClick={() => {
// 							scroll(-400);
// 						}}
// 						style={{ display: display }}
// 					>
// 						<ArrowLeft />
// 					</LeftArrowContainer>
// 					{data !== undefined ? (
// 						<React.Fragment>
// 							{data?.map((property: any, key: number) => {
// 								return (
// 								<InnerContainer key={key}>
//       							<PropertyCard
// 										PropertyId={property._id}
// 											dispatch={dispatch}
// 										session={session}
// 											preview={false}
// 		                                       profile_context={profile_context}
// 		/>
//				 </InnerContainer>
// 								);
// 							})}
// 						</React.Fragment>
// 					) : (
// 						<InnerContainer>
// 							<PropertyLoadingCard />
// 						</InnerContainer>
// 					)}
// 					<RighArrowContainer
// 						variant="contained"
// 						onClick={() => scroll(400)}
// 						style={{ display: display }}
// 					>
// 						<ArrowRight />
// 					</RighArrowContainer>
// 				</Container>
// 			</Columncontain>
// 		</React.Fragment>
// 	);
